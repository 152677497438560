const Plyr = require('plyr');

function go() {

  const showreelPlayer = new Plyr('#player', {
    controls: [
      'play', 'play-large', 'progress', 'mute', 'volume', 'fullscreen',
    ],
    clickToPlay: true,
  });

  const workButtons = document.querySelectorAll('.work-item-button');
  const modalOuter = document.querySelector('.modal-outer');
  const modalInner = document.querySelector('.modal-inner');


  workButtons.forEach(button => {
    button.addEventListener('click', openModal);
  });

  modalOuter.addEventListener('click', e => {
    const isOutside = !e.target.closest('.modal-inner');
    if (isOutside) {
      closeModal();
    }
  });

  const closeIcon = `<svg fill="currentColor" width="100%" height="100%" viewBox="0 0 141 141" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><path d="M121.28,19.505l-102.28,100.952" style="fill:none;stroke:#000;stroke-width:38px;"/><path d="M120.777,121.28l-100.952,-102.28" style="fill:none;stroke:#000;stroke-width:38px;"/></svg>`
  let focusedElement = '';
  function openModal(e) {
    showreelPlayer.pause();   
    const workItem = e.currentTarget;
    const title = workItem.querySelector('h2').innerText;
    const videoProvider = workItem.dataset.videoProvider;
    const videoNumber = workItem.dataset.videoNumber;
    const credit = workItem.dataset.credit;
    focusedElement = workItem; 
    const credits = credit.split(',');
    const creditsList = credits.map(item => `<li>${item}</li>`).join('');
  
    
    modalInner.innerHTML =
    `<button class="close-button absolute top-2 right-2 sm:top-4 sm:right-4 font-bold"><div class="w-4 h-4">${closeIcon}</div></button>
    <div class="px-1">
    <h2 class="text-2xl font-bold pb-1 sm:pb-2">${title}</h2>
    <ul class="text-sm md:text-lg pb-1">${creditsList}</ul>
    </div>
    <div id="modal-player" data-plyr-provider="${videoProvider}" data-plyr-embed-id="${videoNumber}"></div>`

    
    

    const modalPlyr = new Plyr('#modal-player', {
      controls: ['play', 'play-large', 'progress', 'mute', 'volume', 'fullscreen', ]
    });
    const closeButton = document.querySelector('.close-button');
    closeButton.focus();
    closeButton.addEventListener('click', closeModal, {
      once: true
    });

    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        closeModal();
      }
    });
    modalOuter.classList.remove('opacity-0', 'pointer-events-none', 'translate-y-5');

    // modalPlyr.on('ready', () => {
    //     console.log(modalPlyr);
    //     modalPlyr.play();
    //     console.log(modalPlyr.playing);
    //   }
    // );



    // setTimeout(() => {modalPlyr.play()}, 1000);
    // console.log(modalPlyr);

    // function playModalPlyr() {
    //   if (modalPlyr.ready) {
    //     setTimeout(() => modalPlyr.play(), 400)
    //   } else {
    //     setTimeout(playModalPlyr, 200);
    //   }
    // }

    // playModalPlyr();

  }
  
  function closeModal() {
    modalOuter.classList.add('opacity-0', 'pointer-events-none', 'translate-y-5');
    setTimeout(() => {
      modalInner.innerHTML = ''
    }, 100);
    focusedElement.focus();
  }

}

go();